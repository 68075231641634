var timeoutNotification = function() {
    var instance = {};
    var notificationWindow = null;

    var defaults = {
        continueEvent: 'timeoutActivity',
        eventBroadcast: document
    };

    var config = {};

    /**
     * Merges config objects together
     *
     * obj defaultConfig
     * obj userConfig
     *
     * return obj - merged configs
     */
    function mergeConfigs(defaultConfig, userConfig) {
        var merged = [];
        if (userConfig == null) {
            merged = defaultConfig;
        } else {
            for (var key in defaultConfig)
                if (userConfig.hasOwnProperty(key)) {
                    merged[key] = userConfig[key];
                } else {
                    merged[key] = defaultConfig[key];
                }
        }
        return merged;
    }

    /**
     * Triggers an event on the registered eventBroadcast element
     */
    function triggerEvent(name, data) {
        var event = document.createEvent('CustomEvent');

        if (typeof data == 'undefined') {
            data = {};
        }
        event.initCustomEvent(name, true, true, data);
        config.eventBroadcast.dispatchEvent(event);
    }

    instance.isNotificationOpen = function() {
        return (notificationWindow != null);
    };

    instance.restartTimer = function(timerElement, countdownMilliseconds) {

        var duration = countdownMilliseconds / 1000,
            start = Date.now(),
            diff,
            minutes,
            seconds,
            timerIntervalReference;

        function timer() {
            var elapsed = (Date.now() - start) / 1000;
            if (duration >= elapsed) {
                diff = duration - elapsed;

                minutes = (diff / 60) | 0;
                seconds = (diff % 60) | 0;

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                timerElement.html(minutes + ":" + seconds);

                if (diff <= 0) {
                    start = Date.now() + 1000;
                }
            } else {
                notificationWindow.dialog("close");
                clearInterval(timerIntervalReference);
            }
        };

        timer();
        timerIntervalReference = setInterval(timer, 1000);

        timerElement.on('remove', function(){
            clearInterval(timerIntervalReference);
        });
    };

    instance.removeNotification = function() {
        if (notificationWindow != null) {
            notificationWindow.dialog('close');
        }
    };

    instance.showNotification = function(countdownMilliseconds) {

        if (notificationWindow != null) {
            instance.removeNotification();
        }

        var countDownTimerElement = $('<span class="timer">');
        var notificationContent =
            '<span class="ui-icon ui-icon-alert" style="display:inline-block;"></span>You will be signed out soon due to inactivity';

        notificationWindow = $("<div id='timeoutNotification'></div>").dialog({
            modal: true,
            open: function (event, ui) {
                $('.ui-dialog').css('z-index',103);
                $('.ui-widget-overlay').css('z-index',102);
                $(".ui-dialog-titlebar-close", ui.dialog | ui).hide();
            },
            buttons: [{
                text: "Remain signed in",
                click: function () {
                    triggerEvent(config.continueEvent);
                    $(this).dialog("close");
                }
            }],
            close: function (event, ui) {

            },
            resizable: false,
            title: "System Timeout"
            // width: 300,
            // position: { my: "center", at: "center", of: window },

        }).html(notificationContent)
            .append(countDownTimerElement);
        instance.restartTimer(
            countDownTimerElement,
            countdownMilliseconds
        );

    };

    var constructor = function(userConfig) {

        config = mergeConfigs(defaults, userConfig);
        return instance;
    };

    return constructor;
}();
